
import { objectFlip } from '@/utilities'
import { PRODUCT_FILTER_TYPES } from '@/constants'
import { mapState, storeToRefs } from 'pinia'
import { useMarketStore } from '~/stores/market'
import { useUiStore } from '@/stores/ui'

export default {
  name: 'MPFilters',
  setup() {
    const marketStore = useMarketStore()
    const uiStore = useUiStore()
    const { resolution } = storeToRefs(uiStore)
    return {
      marketStore,
      resolution,
    }
  },
  data() {
    return {
      expandedFilters: false,
    }
  },
  computed: {
    ...mapState(useMarketStore, ['tag', 'selfCategory']),
    showToggleAllFilters() {
      return !Object.keys(this.$route.query).includes('q[default_facets]')
    },
    isIOS() {
      if (process.client) {
        const userAgent =
          navigator.userAgent || navigator.vendor || window.opera
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    showCategories() {
      return (
        this.$route.params.brand ||
        this.$route.params.term ||
        this.$route.params.merchant ||
        this.$route.params.tag
      )
    },
    hasFoundProducts() {
      return this.buckets.length > 0
    },
    filtersDefault() {
      return this.marketStore.localFilters?.default || []
    },
    defaultFilters() {
      const excludedFields = ['category_id']
      if (this.$route.params.merchant) excludedFields.push('marketing_name_id')
      if (this.$route.name.includes('mega-discounts'))
        excludedFields.push('with_discount')
      if (this.$route.params.brand) excludedFields.push('brands')
      return this.filtersDefault.filter(
        item => !excludedFields.includes(item.field),
      )
    },
    customFilters() {
      return this?.marketStore.localFilters?.custom || []
    },
    isBrandORMerchantPage() {
      return this.$route.params.brand || this.$route.params.merchant
    },
    lengthCategoriesFilters() {
      const categories = this.marketStore.filters?.default?.find(
        filter => filter?.field === 'categories_id',
      )
      return categories?.buckets?.[0].children.length || []
    },
    isVisibleClearFilter() {
      return (
        !this.$route.params.brand ||
        !this.$route.params.term ||
        this.$route.params.merchant
      )
    },
    hasOnlyOneBoolean() {
      return (
        this.defaultFilters.filter(filter => filter.filter_type === 'boolean')
          ?.length > 1
      )
    },
    buckets() {
      let buckets = []
      this.filtersDefault.forEach(filter => {
        buckets = [...buckets, ...this.getDefaultFilterValue(filter)]
      })
      this.customFilters.forEach(filter => {
        filter.buckets.forEach(bucket => {
          if (bucket.checked) {
            buckets.push({ ...bucket, filterID: filter.id })
          }
        })
      })

      return buckets
    },
  },
  watch: {
    $route: {
      handler() {
        this.initQueries()
      },
      deep: true,
    },
  },
  mounted() {
    this.initQueries()
    this.setVH()
    window.addEventListener('resize', this.setVH)
  },
  beforeDestroy() {
    this.marketStore.productFilters = {}
    window.removeEventListener('resize', this.setVH)
  },
  methods: {
    setVH() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    lastChecked(last) {
      const fil = this.defaultFilters.find(f => f.field == 'brands')
      fil.last = last
    },
    clearFilters() {
      // return if empty filters
      const rout_query = this.$route.query
      if (Object.keys(rout_query).length === 0) {
        return
      }
      if (
        (this.tag.brand || this.tag.master_field) &&
        this.$route.name?.includes('tags-tag')
      ) {
        try {
          this.$router.push({
            path: this.localePath({
              name: 'categories-slug',
              params: {
                slug: `${this.selfCategory?.data.id}-${this.selfCategory?.data?.slugged_name}`,
              },
            }),
          })
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') throw error
        }
      } else {
        this.$root.$emit('mp-clear-filters', true)
        this.marketStore.productFilters = {}
        this.marketStore.localFilters.default.map(filter => {
          if (filter.filter_type === 'multi' && filter.buckets)
            filter.buckets.map(bucket => {
              bucket.checked = false
            })
          else if (filter.filter_type === 'boolean') {
            filter.checked = false
          }
        })
        this.marketStore.localFilters.custom.map(filter => {
          if (filter.buckets)
            filter.buckets.map(bucket => {
              bucket.checked = false
            })
        })
      }
    },
    isBooleanField(field) {
      return ['has_taxit_available'].includes(field)
    },
    initQueries() {
      const queries = JSON.parse(JSON.stringify(this.$route.query))
      const _selectedQueries = {}
      Object.keys(queries).map(key => {
        const params =
          typeof queries?.[key] === 'string'
            ? queries?.[key]
            : Array.isArray(queries?.[key])
            ? queries?.[key]?.[0]
            : ''
        if (key !== 'page') {
          const _key = key.replace('q[', '').replace(']', '')
          _selectedQueries[objectFlip(PRODUCT_FILTER_TYPES)[_key]] = params
            ? params?.split('|')
            : params || ''
        }
      })
      // Default filters
      this.filtersDefault.map(filter => {
        if (
          filter.field === 'retail_price' &&
          Object.keys(_selectedQueries).includes('price_from') &&
          Object.keys(_selectedQueries).includes('price_to')
        ) {
          this.$set(filter, 'selectedMin', +_selectedQueries.price_from[0])
          this.$set(filter, 'selectedMax', +_selectedQueries.price_to[0])
        }

        const filterFields = ['in_stock', 'with_discount', 'premium_partner']

        if (
          filterFields.includes(filter.field) &&
          Object.keys(_selectedQueries).includes(filter.field)
        ) {
          this.$set(
            filter,
            'checked',
            Boolean(_selectedQueries[filter.field][0]),
          )
        }

        if (
          Object.keys(_selectedQueries).includes(filter.field) &&
          filter?.buckets
        ) {
          filter?.buckets?.map(bucket => {
            if (
              _selectedQueries[filter.field].includes(bucket.title) ||
              _selectedQueries[filter.field].includes(bucket.uuid)
            ) {
              this.$set(bucket, 'checked', true)
            }
          })
        }
      })

      this.customFilters.map(filter => {
        if (
          Object.keys(_selectedQueries).includes('facets_multiple') ||
          Object.keys(_selectedQueries).includes('facets_range')
        ) {
          const facets = [
            ...(_selectedQueries?.facets_multiple || []),
            ...(_selectedQueries?.facets_range || []),
          ]
          // Accumulate parsed facets correctly
          const parsedFacets = []
          facets.forEach(facet => {
            parsedFacets.push(...facet.split('|')) // Add all split values
          })
          const _selectedFacets = parsedFacets.map(pFacet => {
            return {
              key: +pFacet?.split('_')?.[0],
              value: pFacet?.split('_')?.[1],
            }
          })

          _selectedFacets.map(selectedFacet => {
            if (+filter.id === selectedFacet.key) {
              if (filter && filter.buckets) {
                filter.buckets.map(bucket => {
                  if (filter?.master_field_type === 'range') {
                    if (String(bucket.id) === String(selectedFacet.value)) {
                      bucket.checked = true
                    }
                  } else if (bucket.title === selectedFacet.value) {
                    bucket.checked = true
                  }
                })
              }
            }
          })
        }
      })
    },
    toggleExpand() {
      if (this.resolution === 'sm' || this.resolution === 'md') {
        const sectionFilters = this.$refs.sectionFilters
        sectionFilters.style = `height: ${window.innerHeight - 161}px`
        this.expandedFilters = !this.expandedFilters
        if (this.expandedFilters) {
          document.body.classList.add('overflow-hidden')
        } else {
          document.body.classList.remove('overflow-hidden')
        }
      }
    },
    getDefaultFilterValue(filter) {
      const simpleFields = ['premium_partner', 'with_discount', 'in_stock']
      const bucketFields = ['brands', 'marketing_name_id']

      // Handle simple fields with `checked` property
      if (simpleFields.includes(filter.field) && filter.checked) {
        return [
          {
            field: filter.field,
            checked: filter.checked,
            title: filter.title,
          },
        ]
      }

      // Handle price-specific logic
      if (filter.field === 'retail_price') {
        const bucket = this.getBucketsPriceFilter(filter)
        return bucket ? [bucket] : []
      }

      // Handle fields with `buckets` data
      if (bucketFields.includes(filter.field)) {
        return filter.buckets
          .filter(item => item.checked)
          .map(item => ({
            ...item,
            field: filter.field,
          }))
      }

      // Default case: empty array
      return []
    },
    getBucketsPriceFilter(filter) {
      const maxPrice = +this.$route?.query?.price_max || +filter?.max
      const minPrice = +this.$route?.query?.price_min || +filter?.min
      const selectedMaxPrice = +this.$route?.query?.price_to
      const selectedMinPrice = +this.$route?.query?.price_from
      const isFilterPriceActive = selectedMaxPrice || selectedMinPrice
      if (
        isFilterPriceActive &&
        (maxPrice >= selectedMaxPrice || minPrice <= selectedMinPrice)
      ) {
        return {
          field: 'retail_price',
          checked: true,
          title: `${selectedMinPrice || filter.selectedMin} - ${
            selectedMaxPrice || filter.selectedMax
          } ₼`,
        }
      }
      return null
    },
  },
}
