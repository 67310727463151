import { difference } from '@/utilities'
import { useMarketStore } from '~/stores/market'
export default {
  setup() {
    const marketStore = useMarketStore()
    return {
      marketStore,
    }
  },
  computed: {},
  beforeMount() {
    this.marketStore.setLocalFilters(
      JSON.parse(JSON.stringify(this.marketStore.filters)),
    )
  },
  methods: {
    productFiltersFunc(new_query, old_query) {
      // FIX CATEGORIES_IN_QUERY_PARAMETRS
      const reset_filters_change_categories_id = !(
        new_query?.category_id === old_query?.query?.category_id
      )
      const empty_filters = new_query ? !Object.keys(new_query).length : false
      if (reset_filters_change_categories_id || empty_filters) {
        this.marketStore.setLocalFilters(
          JSON.parse(JSON.stringify(this.marketStore.filters)),
        )
        return
      }
      // FIX CATEGORIES_IN_QUERY_PARAMETRS-END

      const differentCustom = []
      const differentDefault = []

      // Default Filters
      const hasBackendDefaultFiltersMoreThanLocal =
        this.marketStore.filters.default?.length >
        this.marketStore.localFilters?.default?.length

      // //FIX MANUFACTURE FILTER ITEMS
      if (!hasBackendDefaultFiltersMoreThanLocal) {
        const local_filters_manufacturer =
          this.marketStore.localFilters.default?.find(
            filter => filter?.field === 'manufacturer',
          )
        const filters_manufacturer = this.marketStore.filters.default?.find(
          filter => filter?.field === 'manufacturer',
        )
        const index_filters_manufacturer =
          this.marketStore.filters.default?.findIndex(
            filter => filter?.field === 'manufacturer',
          )
        if (
          local_filters_manufacturer?.buckets?.length >
            filters_manufacturer?.buckets?.length &&
          !this.$route.query['q[has_taxit_available]']
        ) {
          this.marketStore.filters.default[index_filters_manufacturer] =
            filters_manufacturer
        }
      }
      // FIX MANUFACTURE FILTER ITEMS-END

      const defaultFilterForLoop = hasBackendDefaultFiltersMoreThanLocal
        ? this.marketStore.filters.default
        : this.marketStore.localFilters.default

      for (const filter of defaultFilterForLoop) {
        if (
          (filter?.id || filter?.field) ===
          (this.marketStore.lastCheckedFilter?.id ||
            this.marketStore.lastCheckedFilter?.field)
        ) {
          const checkedFilter = this.marketStore.localFilters.default.find(
            item => (item.id || item.field) === (filter.id || filter.field),
          )
          if (checkedFilter) {
            const checkedFilterBackend = this.marketStore.filters.default.find(
              item => (item.id || item.field) === (filter.id || filter.field),
            )

            if (
              checkedFilter?.filter_type === 'multi' &&
              checkedFilter?.buckets
            ) {
              if (
                checkedFilterBackend?.buckets.length >
                checkedFilter?.buckets.length
              ) {
                checkedFilter.buckets = checkedFilterBackend?.buckets
              }
            } else if (checkedFilter?.filter_type === 'boolean') {
              checkedFilter.checked = checkedFilterBackend?.checked
            }

            differentDefault.push(checkedFilter)
          }
        } else {
          const checkedFilter = this.marketStore.filters.default.find(
            item => (item?.id || item?.field) === (filter?.id || filter?.field),
          )
          if (checkedFilter) {
            differentDefault.push(checkedFilter)
          }
        }
      }
      // Default Filters end

      // Custom Filters
      const hasBackendCustomFiltersMoreThanLocal =
        this.marketStore.filters.custom.length >
          this.marketStore.localFilters.custom.length ||
        this.marketStore.resetLocalFilters
      const customFilterForLoop = hasBackendCustomFiltersMoreThanLocal
        ? this.marketStore.filters.custom
        : this.marketStore.localFilters.custom

      for (const filter of customFilterForLoop) {
        if (filter?.id === this.marketStore.lastCheckedFilter?.id) {
          const checkedFilter = this.marketStore.localFilters.custom.find(
            item => item.id === filter.id,
          )
          if (checkedFilter) {
            differentCustom.push(checkedFilter)
          }
        } else {
          const checkedFilter = this.marketStore.filters.custom.find(
            item => item.id === filter.id,
          )
          if (checkedFilter) {
            differentCustom.push(checkedFilter)
          }
        }
      }
      // Custom Filters end

      const queryFacets =
        this.$route?.query?.facets_multiple ||
        this.$route?.query?.facets_range ||
        null

      // Custom Filter
      const backupFilters = {
        prev: JSON.parse(JSON.stringify(this.marketStore.localFilters.custom)),
        current: JSON.parse(JSON.stringify(differentCustom)),
      }
      if (queryFacets) {
        const facets = queryFacets.split('|').map(item => item.split('_'))
        const prevFilterState = difference(
          backupFilters.prev,
          backupFilters.current,
        )

        for (const facet of facets) {
          for (const filter of prevFilterState) {
            if (filter.id === +facet[0]) {
              if (filter?.buckets) {
                filter.buckets.map(bucket => {
                  if (bucket.title === facet[1]) {
                    const filterBucketForUpdate = differentCustom.find(
                      item => item.id === filter.id,
                    )
                    if (
                      !filterBucketForUpdate?.buckets.some(
                        bucket => bucket.title === facet[1],
                      )
                    )
                      filterBucketForUpdate?.buckets.unshift(bucket)
                  }
                })
              }
            }
          }
        }
      }

      // Custom Filter
      const defaultBackupFilters = {
        prev: JSON.parse(JSON.stringify(this.marketStore.localFilters.default)),
        current: JSON.parse(JSON.stringify(differentDefault)),
      }
      if (queryFacets) {
        const facets = queryFacets.split('|').map(item => item.split('_'))
        const defaultPrevFilterState = difference(
          defaultBackupFilters.prev,
          defaultBackupFilters.current,
        )

        for (const facet of facets) {
          for (const filter of defaultPrevFilterState) {
            if (filter.id === +facet[0]) {
              if (filter?.buckets) {
                filter.buckets.map(bucket => {
                  if (bucket.title === facet[1]) {
                    const filterBucketForUpdate = differentDefault.find(
                      item => item.id === filter.id,
                    )
                    if (
                      !filterBucketForUpdate?.buckets.some(
                        bucket => bucket.title === facet[1],
                      )
                    )
                      filterBucketForUpdate.buckets.unshift(bucket)
                  }
                })
              }
            }
          }
        }
      }

      this.marketStore.localFilters.custom = differentCustom
      this.marketStore.localFilters.default = differentDefault
      this.marketStore.setResetFilters(false)
    },
  },
}
