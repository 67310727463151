
import pageViewGTM from '@/mixins/pageViewGTM'
import productFilters from '@/mixins/productFilters'
import { useMarketStore } from '@/stores/market'
import {
  scrollTo,
  setViewSearchResultsSetup,
  rtbAnalytics,
  decodeQuery,
  filterQueryWhiteList,
  getSearchQueries,
} from '@/utilities'
import { PER_PAGE, SEARCH_QUERIES } from '@/constants'
import setDataLayer from '~/mixins/setDataLayer'
import { storeToRefs } from 'pinia'
import { useBloomreachStore } from '@/stores/bloomreach'
import seoPopularProducts from './seo_popular_products'
import { useUiStore } from '@/stores/ui'

export default {
  name: 'MerchantPage',

  mixins: [pageViewGTM, productFilters, setDataLayer, seoPopularProducts],
  layout: 'market',
  setup() {
    const marketStore = useMarketStore()
    const { productFilters } = storeToRefs(marketStore)
    const bloomReachStore = useBloomreachStore()
    return {
      marketStore,
      productFilters,
      bloomReachStore,
    }
  },
  async asyncData({ params, query, route, error }) {
    const marketStore = useMarketStore()
    const merchantId = params?.merchant?.split('-')[0]
    const seoTagsOptions = {
      'q[marketing_name_id_eq]': params?.merchant?.split('-').shift(),
      'q[tag_type]': 'seo',
      'q[opaque_id]': route?.path,
    }
    if (query?.category) seoTagsOptions['q[category_id_in]'] = query?.category
    let categories
    try {
      await marketStore.GET_MERCHANT_NAME_BY_ID({
        id: merchantId,
        options: {
          'include_fields[]': 'name',
        },
      })
      const uiStore = useUiStore()
      const page = query?.page || 1
      if (process.browser) {
        marketStore.loadingProducts = true
      }
      const config = {
        page,
        filters: true,
        facets: !!query?.category_id,
      }
      let options = {
        page,
        per_page: uiStore.fromBB ? 8 : PER_PAGE,
        marketing_name_id: merchantId,
      }
      options.sort = query.sort || 'discount_score_desc'

      const queryParams = query
      Object.keys(query).forEach(key => {
        queryParams[key] = Array.isArray(query?.[key])
          ? query?.[key]?.[0]
          : query?.[key]
      })
      options = {
        ...decodeQuery(filterQueryWhiteList(queryParams)),
        ...options,
      }
      await marketStore.getProducts({ options, config })
      marketStore.moreProductOptions = { options }
      marketStore.seoFaqData = await marketStore.getSeoFaq({ merchantId })
      categories = marketStore.childCategoryItems
      await marketStore.getSeoTags(seoTagsOptions)
    } catch (e) {
      error({ statusCode: 500, message: '' })
    } finally {
      marketStore.loadingProducts = false
    }
    return {
      categories,
      initLoad: false,
    }
  },
  data() {
    return {
      source: 'seller',
      initLoad: true,
    }
  },
  // async fetch() {
  //   const query = this.$route.query
  //   const merchantId = this.$route?.params?.merchant?.split('-')[0]
  //   const seoTagsOptions = {
  //     'q[marketing_name_id_eq]': this.$route?.params?.merchant
  //       ?.split('-')
  //       .shift(),
  //     'q[tag_type]': 'seo',
  //     'q[opaque_id]': this.$route?.path,
  //   }
  //   if (query?.category) seoTagsOptions['q[category_id_in]'] = query?.category
  //   try {
  //     await this.marketStore.GET_MERCHANT_NAME_BY_ID({
  //       id: merchantId,
  //       options: {
  //         'include_fields[]': 'name',
  //       },
  //     })
  //     await this.loadProducts()
  //     const response = await this.marketStore.getSeoFaq({ merchantId })
  //     this.marketStore.seoFaqData = response
  //     this.categories = this.marketStore.childCategoryItems
  //     await this.marketStore.getSeoTags(seoTagsOptions)
  //   } catch (e) {
  //     console.log('Error: Merchant page $fetch ', e)
  //   } finally {
  //     this.initialLoad = false
  //   }
  // },
  head() {
    const scripts = []
    const seoScript = this.getSeoPopular()
    if (seoScript) scripts.push(seoScript)
    return {
      title: this.$t('merchant_title', {
        brand: this.marketStore.merchantName,
      }),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('merchant_description', {
            brand: this.marketStore.merchantName,
          }),
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$t('merchant_title', {
            brand: this.marketStore.merchantName,
          }),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t('merchant_description', {
            brand: this.marketStore.merchantName,
          }),
        },
      ],
      script: scripts,
    }
  },
  computed: {
    title_page() {
      return this.marketStore.merchantName
    },
    categoryOptions() {
      const selectedCategory =
        this.categories?.find(
          category =>
            category.id === this.marketStore.defaultCategoryMegaDiscounts,
        ) || this.title_page
      const categoryNames = selectedCategory
        ? [this.title_page, selectedCategory.name]
        : [this.title_page]

      return {
        categoryNames,
        selectedCategory,
        products: this.marketStore.products,
        partner: this.title_page,
      }
    },
  },
  watch: {
    productFilters(query) {
      const defaultQuery = { ...this.$route.query }
      Object.keys(defaultQuery).forEach(key => {
        if (!SEARCH_QUERIES.includes(key)) {
          delete defaultQuery[key]
        }
      })
      this.$router
        .replace({ query: { ...defaultQuery, ...query } })
        .then(() => {
          this.viewItemList()
        })
        .catch(error => {
          if (error.name !== 'NavigationDuplicated') throw error
        })
    },
    $route: {
      handler() {
        // this.loadProducts()
        this.productFiltersFunc()
        this.$nextTick(() => {
          this.bloomReachStore.viewCategory(this.categoryOptions)
        })
      },
      deep: true,
    },
  },
  watchQuery: true,
  async mounted() {
    scrollTo(document.getElementsByClassName('Layout')[0], 0, 100)
    this.$root.$on('mp-clear-filters', cleared => {
      if (cleared) {
        const query = getSearchQueries({
          routeQueries: this.$route?.query,
        })
        try {
          this.$router.push(
            this.localePath({
              name: 'brands-brand',
              params: {
                brand: this.$route.params.brand,
              },
              query,
            }),
          )
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') throw error
        }
      }
    })
    if (!this.marketStore.minInstallmentAmount) {
      await this.marketStore.getMinInstallmentAmount()
    }
    this.viewItemList()
    rtbAnalytics(this.$i18n.locale, {
      eventType: 'placebo',
    })
    setViewSearchResultsSetup(
      this.$route,
      this.marketStore.pagination?.total_entries,
      this.$i18n.locale,
    )
    if (this.$route?.query.from_search) {
      this.source = 'search'
    }

    this.bloomReachStore.viewCategory(this.categoryOptions)
  },
  // destroyed() {
  //   this.marketStore.products = []
  //   this.marketStore.pagination = {}
  //   this.marketStore.localFilters = {}
  //   this.marketStore.filters = {}
  // },
  // methods: {
  //   async loadProducts() {
  //     const uiStore = useUiStore()
  //     const query = this.$route.query
  //     const page = query?.page || 1
  //     const merchantId = this.$route?.params?.merchant?.split('-')[0]
  //     if (process.browser) {
  //       this.marketStore.loadingProducts = true
  //     }
  //     const config = {
  //       page,
  //       filters: true,
  //       facets: !!query?.category_id,
  //     }
  //     let options = {
  //       page,
  //       per_page: uiStore.fromBB ? 8 : PER_PAGE,
  //       marketing_name_id: merchantId,
  //     }
  //     options.sort = query.sort || 'discount_score_desc'
  //
  //     const queryParams = query
  //     Object.keys(query).forEach(key => {
  //       queryParams[key] = Array.isArray(query?.[key])
  //         ? query?.[key]?.[0]
  //         : query?.[key]
  //     })
  //     options = {
  //       ...decodeQuery(filterQueryWhiteList(queryParams)),
  //       ...options,
  //     }
  //     try {
  //       await this.marketStore.getProducts({ options, config })
  //       this.marketStore.moreProductOptions = { options }
  //     } catch (e) {
  //       console.log('Error: loadProducts ', e)
  //     } finally {
  //       this.marketStore.loadingProducts = false
  //     }
  //   },
  // },
}
