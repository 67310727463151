
import { useMarketStore } from '~/stores/market'
import { useUiStore } from '@/stores/ui'
import { storeToRefs } from 'pinia'
import { useDataLayerStore } from '@/stores/dataLayer'

export default {
  name: 'MPSortBy',
  setup() {
    const marketStore = useMarketStore()
    const uiStore = useUiStore()
    const dataLayerStore = useDataLayerStore()
    const { resolution } = storeToRefs(uiStore)
    return {
      marketStore,
      uiStore,
      dataLayerStore,
      resolution,
    }
  },
  data() {
    return {
      options: [
        {
          label: this.$t('market.cheapest'),
          value: 'retail_price_asc',
        },
        {
          label: this.$t('market.expensive'),
          value: 'retail_price_desc',
        },
        {
          label: this.$t('market.popular'),
          value: this.getValuePopular(),
        },
        {
          label: this.$t('market.sortByDiscount'),
          value: 'discount_desc',
        },
        {
          label: this.$t('market.new_items'),
          value: 'activated_at_desc',
        },
      ],
      config: {
        dropdownClass: 'MPSortBy-Dropdown',
      },
    }
  },
  computed: {
    showDesktop() {
      return (
        this.resolution === 'lg' ||
        this.resolution === 'xxl' ||
        this.resolution === 'xl'
      )
    },
    selected: {
      get() {
        const query = this.$route?.query?.sort
        return query
          ? this.marketStore.productsSortBy
          : this.defaultSelectedSort()
      },
      set(val) {
        this.marketStore.productsSortBy = val
      },
    },
  },
  beforeMount() {
    setTimeout(() => {
      if (
        this.$route?.query?.sort &&
        this.selected !== this.$route.query?.sort
      ) {
        this.selected = this.$route.query?.sort
      }
    }, 500)
  },
  methods: {
    contentSortBy(by) {
      const categories = this.marketStore.filters?.default?.find(
        filter => filter.field === 'categories.id',
      )
      if (
        (this.$route.params?.tag || this.$route.params?.term) &&
        categories?.buckets?.length > 1
      ) {
        this.uiStore.isPopupTagsCategoriesVisible = true
      } else {
        const options = {
          retail_price_asc: 'price_low_to_high',
          retail_price_desc: 'price_high_to_low',
          category_popular_score_desc: 'popular',
          global_popular_score_desc: 'popular',
          'id boosted_score': 'popular',
          discount_score_desc: 'popular',
          discount_desc: 'discount',
          activated_at_desc: 'newest',
        }
        if (typeof dataLayer !== 'undefined') {
          dataLayer.push({
            event: 'sorting_applied',
            choice: options?.[by] || '',
          })
        }
        const query = Object.assign({}, this.$route.query)
        query.sort = by
        if (by !== this.$route.query.sort) {
          this.$router.replace({ query }).catch(error => {
            if (error.name !== 'NavigationDuplicated') throw error
          })
        }
      }
    },
    defaultSelectedSort() {
      if (this.$route.name?.includes('categories')) {
        return 'category_popular_score'
      } else if (this.$route.name?.includes('tag')) {
        return 'boosted_score'
      } else if (this.$route.name?.includes('search')) {
        return 'boosted_score'
      } else if (this.$route.name?.includes('mega-discounts')) {
        return 'discount_score_desc'
      } else if (this.$route.name?.includes('new-products')) {
        return 'activated_at_desc'
      } else {
        return 'discount_score_desc'
      }
    },
    getValuePopular() {
      if (this.$route.name?.includes('categories')) {
        return 'category_popular_score'
      } else if (this.$route.name?.includes('tag')) {
        return 'boosted_score'
      } else if (this.$route.name?.includes('search')) {
        return 'boosted_score'
      } else {
        return 'discount_score_desc'
      }
    },
    onClick(isOpened) {
      if (!isOpened) this.dataLayerStore.setClick(this.$route)
    },
  },
}
