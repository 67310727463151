
import { marked } from 'marked'
import { useMarketStore } from '~/stores/market'

export default {
  name: 'MPCategorySEO',
  props: {
    isCategory: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const marketStore = useMarketStore()
    return {
      marketStore,
    }
  },
  data() {
    return {
      needShowMore: false,
    }
  },
  computed: {
    hasProducts() {
      return !!this.marketStore.PopularNowProducts?.length
    },
    popularProducts() {
      const _products = this.marketStore.PopularNowProducts
      return _products.splice(0, 5)
    },
    seoMarkdownText() {
      if (!this.marketStore.selfCategory || !this.marketStore.selfCategory.data)
        return ''
      return this.marketStore.categorySeoData?.seo_text || ''
    },
    seoHTMLText() {
      if (!this.seoMarkdownText) return ''
      return marked(this.seoMarkdownText) || ''
    },
    tagSeoHTMLText() {
      if (!this.marketStore?.tag?.seo_text) return ''

      return this.marketStore?.tag?.seo_text
    },
    brandSeoHTMLText() {
      if (!this.marketStore.brand) return ''

      return this.marketStore.brand?.seo_text
    },
    isPageOne() {
      return this.marketStore.pagination?.page === 1
    },
    isTagPage() {
      return this.$route.path.includes('/tags/')
    },
    isBrandPage() {
      return this.$route.path.includes('/brands/')
    },
    isCategoryPage() {
      return this.$route.path.includes('/categories/')
    },
    tagPageTitle() {
      return (
        this.marketStore.selfCategory?.data?.name ||
        this.marketStore.tag?.name ||
        ''
      )
    },
    tagIdCategory() {
      return this.marketStore.selfCategory?.data?.id || ''
    },
    showSEOTextTags() {
      return this.isPageOne && this.isTagPage
    },
  },
  mounted() {
    this.checkShowMore()
  },
  methods: {
    checkShowMore() {
      const clientHeight =
        this.$refs?.seoTextBlock?.clientHeight ||
        this.$refs?.seoTextBlockTag?.clientHeight ||
        this.$refs?.seoTextBlockBrand?.clientHeight ||
        0

      if (
        clientHeight &&
        clientHeight > 10 * 19 &&
        (this.seoHTMLText || this.tagSeoHTMLText || this.brandSeoHTMLText)
      )
        this.needShowMore = true
    },
    expandText() {
      this.needShowMore = false
    },
    getLink(product) {
      return `${this.$i18n.locale === 'az' ? '' : '/ru'}/product/${
        product.id
      }-${product.slugged_name}`
    },
  },
}
